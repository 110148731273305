<template>
	<div class="subjective_view">
		<div class="interMant_top">
			<div class="interTop_left">
				<el-avatar :size="50">
					<img :src="infoData.head_img ? 'http://' + infoData.head_img : circleUrl" />
				</el-avatar>
				<div class="otim">
					姓名:
					<span>{{ infoData.name }}</span>
					性别:
					<span>{{ infoData.sex == 1 ? '男' : infoData.sex == 2 ? '女' : '' }}</span>
					身高:
					<span>{{ infoData.height }}cm</span>
					体重:
					<span>{{ infoData.weight }}kg</span>
					项目:
					<span>{{ infoData.sport_name }}</span>
					运动队:
					<span>{{ infoData.department_name }}</span>
				</div>
			</div>
			<div class="interTop_right">
				<el-date-picker
					v-model="value1"
					@change="dateChange"
					:clearable="false"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
					:picker-options="customPickerOptions"
					:unlink-panels="true"
				>
				</el-date-picker>
				<el-tooltip class="item" effect="dark" content="对每个指标的趋势图进行自定义的评价区间设置" placement="top-start">
					<i class="el-icon-info"></i>
				</el-tooltip>
				<el-button @click="EvaluationOpen" class="new_btn" style="background: #0055e9; color: #fff" round>评价区间设置</el-button>
				<el-button @click="entryOpen" class="new_btn" style="background: #1a9f16; color: #fff" round>录入/修改</el-button>
				<span @click="reBack" style="margin-left: 10px">
					<i class="iconfont iconfanhui"></i>
					返回
				</span>
			</div>
		</div>

		<div class="subjective_content" v-loading="Loading">
			<div class="top_title_qs">
				<div class="top_title">
					<span></span>
					状态监控
				</div>
				<div class="color_area"><span></span>好 <span></span>较好 <span></span>一般 <span></span>较差</div>
			</div>
			<div id="echartsFirst" class="echarts"></div>
			<div id="echartsCenter" class="echarts"></div>
			<div id="echartsBottom" class="echarts"></div>
		</div>

		<div class="status_content">
			<div class="top_title_qs">
				<div class="top_title">
					<span></span>
					今日状态自评
				</div>
			</div>
			<div class="echarts_view" v-for="(item, index) in progressData" :key="'status' + index">
				<p>
					{{ item.name }}
					<el-tooltip class="item" effect="dark" :content="item.title" placement="top-start">
						<i class="el-icon-info"></i>
					</el-tooltip>
				</p>
				<div :id="'echartsFour' + index" class="echarts"></div>
			</div>

			<div class="echarts_view">
				<p>状态自评总分</p>
				<div id="echartStatus" class="echarts"></div>
			</div>

			<div class="echarts_view">
				<p>晨起疼痛自评分数 <i></i></p>
				<div id="echartsMorning" class="echarts"></div>
			</div>

			<div class="table_content">
				<div class="table_head">
					<p>每日状态自评统计</p>
					<div><span></span>9-10 好 <span></span>7-8 较好 <span></span>5-6 一般 <span></span>3-4 较差 <span></span>0-2 差</div>
				</div>
				<el-table :data="ztzpTableData" style="width: 100%" height="300">
					<el-table-column label="序号" type="index" width="45" align="center">
						<template slot-scope="scope">
							{{ scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : scope.$index + 1 }}
						</template>
					</el-table-column>
					<el-table-column prop="date" label="日期" align="center"> </el-table-column>
					<el-table-column prop="ztzp" label="自我状态评价" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : setZBackground(scope.row.ztzp) }"
							>
								{{ scope.row.ztzp == '' ? '-' : scope.row.ztzp }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="jrhfd" label="肌肉恢复度" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : setZBackground(scope.row.jrhfd) }"
							>
								{{ scope.row.jrhfd == '' ? '-' : scope.row.jrhfd }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="smzl" label="睡眠质量" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : setZBackground(scope.row.smzl) }"
							>
								{{ scope.row.smzl == '' ? '-' : scope.row.smzl }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ysyw" label="饮食欲望" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : setZBackground(scope.row.ysyw) }"
							>
								{{ scope.row.ysyw == '' ? '-' : scope.row.ysyw }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="sum" label="状态自评总分" align="center">
						<template slot-scope="scope">
							<!-- <div
								class="value_content"
								:style="{ background: scope.$index + 1 == ztzpTableData.length || scope.$index + 1 == ztzpTableData.length - 1 ? '' : setZBackground(scope.row.sum) }"
							> -->
							{{ scope.row.sum == '' ? '-' : scope.row.sum }}
							<!-- </div> -->
						</template>
					</el-table-column>
				</el-table>
			</div>

			<div class="table_content">
				<div class="table_head">
					<p>晨起疼痛自评统计</p>
					<div><span></span>0-2 好 <span></span>3-4 较好 <span></span>5-6 一般 <span></span>7-8 较差 <span></span>9-10 差</div>
				</div>
				<el-table :data="ttzpTableData" style="width: 100%" height="300">
					<el-table-column label="序号" type="index" width="45" align="center">
						<template slot-scope="scope">
							{{ scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : scope.$index + 1 }}
						</template>
					</el-table-column>
					<el-table-column prop="date" label="日期" align="center"> </el-table-column>
					<el-table-column prop="qg" label="躯干" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : setTBackground(scope.row.qg) }"
							>
								{{ scope.row.qg == '' ? '-' : scope.row.qg }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="zsz" label="左上肢" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : setTBackground(scope.row.zsz) }"
							>
								{{ scope.row.zsz == '' ? '-' : scope.row.zsz }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="ysz" label="右上肢" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : setTBackground(scope.row.ysz) }"
							>
								{{ scope.row.ysz == '' ? '-' : scope.row.ysz }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="zxz" label="左下肢" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : setTBackground(scope.row.zxz) }"
							>
								{{ scope.row.zxz == '' ? '-' : scope.row.zxz }}
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="yxz" label="右下肢" align="center">
						<template slot-scope="scope">
							<div
								class="value_content"
								:style="{ background: scope.$index + 1 == ttzpTableData.length || scope.$index + 1 == ttzpTableData.length - 1 ? '' : setTBackground(scope.row.yxz) }"
							>
								{{ scope.row.yxz == '' ? '-' : scope.row.yxz }}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</div>

		<el-dialog title="录入/修改" center :visible.sync="EntryDialog" width="70%" :before-close="EntryHandleClose">
			<div class="entry_dialog">
				<el-date-picker
					v-model="value2"
					@change="entryDateChange"
					:clearable="false"
					value-format="yyyy-MM-dd"
					format="yyyy-MM-dd"
					type="date"
					placeholder="选择日期"
					:picker-options="customPickerOptions"
					:unlink-panels="true"
				>
				</el-date-picker>
				<div class="ctm_content">
					<span>晨脉（次/min）</span>
					<el-input v-model="from.morning" @input="valueChange('morning')"></el-input>
					<span>体重（kg）</span>
					<el-input v-model="from.weight" @input="valueChange('weight')"></el-input>
					<span>睡眠时长（h）</span>
					<!-- <el-input v-model="from.sleep"></el-input> -->
					<el-select v-model="from.sleep" filterable allow-create default-fi rst-option :disabled="edit" placeholder="请选择睡眠时长">
						<el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.label"> </el-option>
					</el-select>
				</div>
				<div class="bot_left bot_con">
					<ul>
						<li v-for="(itm, idx) in progressData" :key="idx">
							<el-tooltip class="item" effect="dark" :content="itm.title" placement="top-start">
								<span class="con_name">{{ itm.name }} <i class="el-icon-info"></i></span>
							</el-tooltip>
							<div class="con_progress">
								<span v-for="(item, index) in 10" :key="index" @click="progressClick(item, idx + 1, true, true)">{{ item }}</span>
								<div class="progress_dia" :style="{ width: progressOptions[idx + 1].width, background: progressOptions[idx + 1].color }" @click="diaClick($event, idx + 1)">
									<span class="ye" :style="{ 'border-color': progressOptions[idx + 1].color, color: progressOptions[idx + 1].color }">{{ progressOptions[idx + 1].value }}</span>
								</div>
							</div>
						</li>
					</ul>
				</div>
				<div class="bot_right bot_con">
					<div class="bot_right_content">
						<div class="body_con">
							<img src="./../../../static/body.png" alt="" />
							<!-- 左上肢选中 -->
							<div class="leftarm" @click="bodyChange('leftarm')">
								<img v-show="leftarm" src="./../../../static/leftarm.png" alt="" />
							</div>

							<!-- 右上肢选中 -->
							<div class="rightarm" @click="bodyChange('rightarm')">
								<img v-show="rightarm" src="./../../../static/rightarm.png" alt="" />
							</div>

							<!-- 左下肢选中 -->
							<div class="leftleg" @click="bodyChange('leftleg')">
								<img v-show="leftleg" src="./../../../static/leftleg.png" alt="" />
							</div>

							<!-- 右下肢选中 -->
							<div class="rightleg" @click="bodyChange('rightleg')">
								<img v-show="rightleg" src="./../../../static/rightleg.png" alt="" />
							</div>

							<!-- 躯干选中 -->
							<div class="bosom" @click="bodyChange('bosom')">
								<img v-show="bosom" src="./../../../static/bosom.png" alt="" />
							</div>
						</div>

						<!-- 左上肢选择框 -->
						<div class="leftarmSelect" v-show="leftarm">
							<span>左上肢</span>
							<el-select v-model="leftarmValue" @change="selectChange(leftarmValue, 1)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 右上肢选择框 -->
						<div class="rightarmSelect" v-show="rightarm">
							<el-select v-model="rightarmValue" @change="selectChange(rightarmValue, 3)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>右上肢</span>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 左下肢选择框 -->
						<div class="leftlegSelect" v-show="leftleg">
							<span>左下肢</span>
							<el-select v-model="leftlegValue" @change="selectChange(leftlegValue, 2)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 右下肢选择框 -->
						<div class="rightlegSelect" v-show="rightleg">
							<el-select v-model="rightlegValue" @change="selectChange(rightlegValue, 4)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>右下肢</span>
							<img src="./../../../static/line.png" alt="" />
						</div>

						<!-- 躯干选择框 -->
						<div class="bosomSelect" v-show="bosom">
							<el-select v-model="bosomValue" @change="selectChange(bosomValue, 5)" :disabled="edit">
								<el-option label="请选择" :value="0"></el-option>
								<el-option v-for="item in 10" :key="item" :label="item" :value="item"></el-option>
							</el-select>
							<span>躯干</span>
							<img src="./../../../static/line.png" alt="" />
						</div>
					</div>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="EntrySave" type="primary" v-no-more-click class="save_btn" round>保存</el-button>
				<el-button @click="EntryHandleClose" class="cancel_btn" round>取消</el-button>
			</span>
		</el-dialog>

		<el-dialog title="评价区间设置" center :visible.sync="EvaluationDialog" width="30%" :before-close="EvaluationHandleClose">
			<div style="text-align: center"><span style="color: #f00">*</span> 系数 <el-input v-model="coefficientValue" type="number" style="width: 80%"></el-input></div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="EvaluationSave" type="primary" v-no-more-click class="save_btn" round>确定</el-button>
				<el-button @click="EvaluationHandleClose" class="cancel_btn" round>取消</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import rightarmImg from './../../../static/rightarm.png'
export default {
	data() {
		return {
			EntryDialog: false,
			EvaluationDialog: false,
			zwsjOne: false,
			zwsjTwo: false,
			zwsjThree: false,
			Loading: true,
			circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
			infoData: {},
			value1: new Date(),
			value2: new Date(),
			customDateArr: [],
			firstNum: true,
			edit: this.$route.query.edit == '1' ? true : false,
			colors: ['#FFE5E5', '#FFFCBB', '#DBECFF', '#D6FFD9'],
			progressOptions: {
				1: {
					color: '#999999',
					value: '0',
					width: '',
				},
				2: {
					color: '#999999',
					value: '0',
					width: '',
				},
				3: {
					color: '#999999',
					value: '0',
					width: '',
				},
				4: {
					color: '#999999',
					value: '0',
					width: '',
				},
			},
			progressData: [
				{
					name: '睡眠质量',
					title: '数字越大，睡眠越好',
					data: [],
				},
				{
					name: '饮食欲望',
					title: '数字越大，食欲越好',
					data: [],
				},
				{
					name: '自我状态评价',
					title: '数字越大，状态越好',
					data: [],
				},
				{
					name: '肌肉恢复度',
					title: '数字越大，恢复越好',
					data: [],
				},
			],
			options: [],
			from: {},
			value: [],
			echartsData: {},
			leftarm: false,
			leftarmValue: 0,

			rightarm: false,
			rightarmValue: 0,

			leftleg: false,
			leftlegValue: 0,

			rightleg: false,
			rightlegValue: 0,

			bosom: false,
			bosomValue: 0,

			coefficientValue: '0.2',
			oldCoefficientValue: '0.2',

			ttzpTableData: [],
			ztzpTableData: [],

			stageJudgeData: [],

			allEchartsData: {},
		}
	},
	mounted() {
		this.options = this.$utils.sleepOptions()
		this.serveInfo()
		this.getDate()
		this.getStageJudge(false)
		this.getRead()
	},
	computed: {
		customPickerOptions() {
			let that = this
			that.type = true
			return {
				cellClassName(date) {
					if (that.customDateArr.includes(that.getFullDate(date))) {
						return 'custom_date_class'
					}
				},
			}
		},
	},
	methods: {
		setEchartsList(rows) {
			this.$nextTick(() => {
				this.setEcharts(rows.echarts)
				this.setMorningEcharts(rows.echarts)
				this.setZtEcharts()
			})
		},
		getRead() {
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/readTable',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.allEchartsData = res.data.data
						this.setEchartsList(res.data.data)
						this.progressData[0].data = res.data.data.echarts[8]
						this.progressData[1].data = res.data.data.echarts[9]
						this.progressData[2].data = res.data.data.echarts[10]
						this.progressData[3].data = res.data.data.echarts[11]

						this.ttzpTableData = res.data.data.table.ttzp
						this.ztzpTableData = res.data.data.table.ztzp
					}
				})
		},
		getStageJudge(type) {
			let date = this.getFullDate(this.value1)
			this.$axios
				.post(
					'/p/statusrecord/stageJudge',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
						sd: this.coefficientValue,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.stageJudgeData = res.data.data
						if (type) {
							this.setEcharts(this.allEchartsData.echarts)
							this.setZtEcharts()
						}
					}
				})
		},
		entryOpen() {
			// this.coefficientValue = ''
			this.coefficientValue = this.oldCoefficientValue
			this.EntryDialog = true
			this.create()
		},
		EvaluationOpen() {
			this.EvaluationDialog = true
		},
		EvaluationSave() {
			if (this.coefficientValue == '') {
				return this.$message({
					type: 'warning',
					message: '请填写系数',
				})
			}
			this.oldCoefficientValue = this.coefficientValue
			this.EvaluationDialog = false
			this.getStageJudge(true)
		},
		EvaluationHandleClose() {
			this.coefficientValue = this.oldCoefficientValue
			this.EvaluationDialog = false
		},
		EntryHandleClose() {
			this.EntryDialog = false
			this.setDialogForm()
		},
		EntrySave() {
			// if(this.value2)
			console.log(this.value2)
			let date = this.getFullDate(this.value2)
			var obj = {
				staff_uuid: this.$route.query.uuid,
				date: date,
				status: {
					1: this.from.morning,
					2: this.from.weight,
					3: this.from.sleep,
					8: this.progressOptions[1].value,
					9: this.progressOptions[2].value,
					10: this.progressOptions[3].value,
					11: this.progressOptions[4].value,
				},
				pain: {
					1: this.leftarmValue,
					3: this.rightarmValue,
					2: this.leftlegValue,
					4: this.rightlegValue,
					5: this.bosomValue,
				},
			}
			// console.log(JSON.stringify(obj))
			this.$axios.post('/p/statusrecord/addAll', obj).then(res => {
				if (res.data.code == 0) {
					this.$message({
						type: 'success',
						message: res.data.message,
					})
					this.getStageJudge()
					this.EntryHandleClose()
					this.getRead()
					this.getDate()
				}
			})
		},
		selectChange(value, type) {
			// let date = this.getFullDate(this.value1)
			// this.$axios
			// 	.post(
			// 		'/p/statusrecord/addPain',
			// 		this.$qs({
			// 			staff_uuid: this.$route.query.uuid,
			// 			date: date,
			// 			position: type,
			// 			value: value,
			// 		})
			// 	)
			// 	.then(res => {
			// 		if (res.data.code == 0) {
			// 		}
			// 	})
		},

		entryDateChange() {
			this.create()
		},
		bodyChange(name) {
			this[name] = !this[name]
			if (!this[name]) this[name + 'Value'] = 0
		},
		dateChange(date) {
			this.getRead()
			// this.create()
			// this.getDate()
		},
		getMonthDate(date) {
			this.type = false
			var nowDate = null
			if (date) {
				nowDate = new Date(date)
			} else {
				nowDate = new Date()
			}
			var fullYear = nowDate.getFullYear()
			var month = nowDate.getMonth() + 1 // getMonth 方法返回 0-11，代表1-12月
			var endOfMonth = new Date(fullYear, month, 0).getDate() // 获取本月最后一天
			var endDate = this.getFullDate(nowDate.setDate(endOfMonth)) //当月最后一天
			var startDate = this.getFullDate(nowDate.setDate(1)) //当月第一天
			this.getDate(startDate, endDate)
		},
		getDate(startDate, endDate) {
			this.$axios
				.post(
					'/p/statusrecord/getDate',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						cate: 1,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.customDateArr = res.data.data
						// this.customDateArr = ['2021-08-24', '2021-08-27', '2021-08-15']
						// console.log(this.customDateArr);
					}
				})
		},
		// 格式化日期格式为 xxxx-xx-xx
		getFullDate(targetDate) {
			var D, y, m, d
			if (targetDate) {
				D = new Date(targetDate)
				y = D.getFullYear()
				m = D.getMonth() + 1
				d = D.getDate()
			} else {
				y = fullYear
				m = month
				d = date
			}
			m = m > 9 ? m : '0' + m
			d = d > 9 ? d : '0' + d
			return y + '-' + m + '-' + d
		},
		serveInfo() {
			this.$axios
				.post(
					'/p/injury/staffInfo',
					this.$qs({
						uuid: this.$route.query.uuid,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.infoData = res.data.data[0]
						this.Loading = false
					}
				})
		},
		reBack() {
			this.$router.push({
				path: '/conditionModule/subjectiveState',
				query: {
					type: 1,
					page: this.$route.query.page,
				},
			})
		},
		setEcharts(rows) {
			let echartsFirst = this.$echarts.init(document.getElementById('echartsFirst'))
			let echartsCenter = this.$echarts.init(document.getElementById('echartsCenter'))
			let echartsBottom = this.$echarts.init(document.getElementById('echartsBottom'))
			let optionOne = {
				title: {
					text: '晨脉',
					top: '3%',
					left: 'center',
					textStyle: {
						fontSize: 16,
						fontFamily: 'PingFangSC-Medium, PingFang SC',
						fontWeight: 500,
						color: '#1f2633',
					},
				},
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '2%',
					top: '20%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						rows[1] &&
						rows[1].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
						interval: 0,
						rotate: 45,
						margin: 15,
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						show: false,
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: '次/min',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						show: false,
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
						inside: true,
					},
				},
				color: ['#0055E9'],
				series: [
					{
						type: 'line',
						data:
							rows[1] &&
							rows[1].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},

						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},

						markArea: {
							silent: true,
							data: [],
						},
					},
				],
			}
			var a_data = []
			this.stageJudgeData[1] &&
				this.stageJudgeData[1].forEach(item => {
					a_data.push([
						{
							name: '',
							yAxis: item.min,
							itemStyle: {
								color: this.colors[item.type - 1],
							},
							label: {
								position: 'insideLeft',
							},
						},
						{
							yAxis: item.max == '' ? 10000 * 10000 : item.max,
						},
					])
				})
			optionOne.series[0].markArea.data = a_data
			let optionTwo = {
				title: {
					text: '体重',
					top: '3%',
					left: 'center',
					textStyle: {
						fontSize: 16,
						fontFamily: 'PingFangSC-Medium, PingFang SC',
						fontWeight: 500,
						color: '#1f2633',
					},
				},
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '20%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						rows[2] &&
						rows[2].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
						interval: 0,
						rotate: 45,
						margin: 15,
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						show: false,
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: 'kg',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						show: false,
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
						inside: true,
					},
				},
				color: ['#0055E9'],
				series: [
					{
						type: 'line',
						data:
							rows[2] &&
							rows[2].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},

						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},

						markArea: {
							silent: true,
							data: [],
						},
					},
				],
			}

			var b_data = []
			this.stageJudgeData[2] &&
				this.stageJudgeData[2].forEach(item => {
					b_data.push([
						{
							name: '',
							yAxis: item.min,
							itemStyle: {
								color: this.colors[item.type - 1],
							},
							label: {
								position: 'insideLeft',
							},
						},
						{
							yAxis: item.max == '' ? 10000 * 10000 : item.max,
						},
					])
				})
			optionTwo.series[0].markArea.data = b_data

			let optionThree = {
				title: {
					text: '睡眠时长',
					top: '3%',
					left: 'center',
					textStyle: {
						fontSize: 16,
						fontFamily: 'PingFangSC-Medium, PingFang SC',
						fontWeight: 500,
						color: '#1f2633',
					},
				},
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '20%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						rows[3] &&
						rows[3].map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
						interval: 0,
						rotate: 45,
						margin: 15,
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						show: false,
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: 'h',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						show: false,
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: true,
						lineStyle: {
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
						inside: true,
					},
				},
				color: ['#0055E9'],
				series: [
					{
						type: 'line',
						data:
							rows[3] &&
							rows[3].map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},

						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},

						markArea: {
							silent: true,
							data: [],
						},
					},
				],
			}
			var c_data = []
			this.stageJudgeData[3] &&
				this.stageJudgeData[3].forEach(item => {
					c_data.push([
						{
							name: '',
							yAxis: item.min,
							itemStyle: {
								color: this.colors[item.type - 1],
							},
							label: {
								position: 'insideLeft',
							},
						},
						{
							yAxis: item.max == '' ? 10000 * 10000 : item.max,
						},
					])
				})
			optionThree.series[0].markArea.data = c_data

			echartsFirst.setOption(optionOne, true)
			echartsCenter.setOption(optionTwo, true)
			echartsBottom.setOption(optionThree, true)
			this.$echartsResize(echartsFirst)
			this.$echartsResize(echartsCenter)
			this.$echartsResize(echartsBottom)
		},

		setMorningEcharts(rows) {
			let echartStatus = this.$echarts.init(document.getElementById('echartStatus'))
			let options = {
				tooltip: {
					trigger: 'axis',
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '20%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data:
						rows.sum &&
						rows.sum.map(item => {
							return {
								value: item.date,
								textStyle: {
									color: '#585D66',
								},
							}
						}),
					axisLabel: {
						padding: [10, 0, 0, 0],
						interval: 0,
						rotate: 45,
						margin: 15,
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						show: false,
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: '',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						show: false,
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: false,
						lineStyle: {
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
						inside: true,
					},
				},
				color: ['#0055E9'],
				series: [
					{
						type: 'line',
						data:
							rows.sum &&
							rows.sum.map(item => {
								return item.value
							}),
						symbol: 'circle', //标记的图形
						symbolSize: 25, // 拐点的大小
						label: {
							normal: {
								show: true,
								color: '#fff', // 数字颜色
								position: ['50%', '35%'],
								offset: [0, 0],
								align: 'center',
							},
						},

						markLine: {
							data: [
								{
									type: 'average',
									name: 'Avg',
									label: {
										show: true,
										formatter: '平均值:{c}',
										position: 'insideEndTop',
									},
								},
							],
						},
					},
				],
			}
			echartStatus.setOption(options)
			this.$echartsResize(echartStatus)

			var x_data = [],
				y_data = { 1: [], 2: [], 3: [], 4: [], 5: [] },
				value_date = {}
			rows.pain.map(item => {
				if (x_data.indexOf(item.date) == -1) {
					x_data.push(item.date)
					value_date[item.date] = {
						1: '',
						2: '',
						3: '',
						4: '',
						5: '',
					}
				}
			})

			rows.pain.map(item => {
				value_date[item.date][item.position] = item.value
			})

			for (var i in value_date) {
				for (var j in value_date[i]) {
					y_data[j].push(value_date[i][j])
				}
			}

			let echartsMorning = this.$echarts.init(document.getElementById('echartsMorning'))
			let option = {
				title: {
					text: '',
					top: '3%',
					left: 'center',
				},
				tooltip: {
					trigger: 'axis',
					formatter: function (params, ticket, callback) {
						var str = `${params[0].axisValue}`
						params.forEach(item => {
							if (item.value !== '') {
								// return item.marker + ' ' + item.seriesName + ':' + item.value
								str += '</br>' + item.marker + ' ' + item.seriesName + ':' + item.value
							}
						})
						return str
					},
				},
				legend: {
					data: ['左上肢', '左下肢', '右上肢', '右下肢', '躯干'],
					right: 60,
					top: 0,
				},
				grid: {
					left: '3%',
					right: '5%',
					bottom: '10%',
					top: '20%',
					containLabel: true,
				},
				xAxis: {
					type: 'category',
					boundaryGap: true,
					data: x_data,
					axisLabel: {
						padding: [10, 0, 0, 0],
						interval: 0,
						rotate: 45,
						margin: 15,
					},
					axisLine: {
						lineStyle: {
							color: '#999',
						},
					},
					axisTick: {
						show: false,
						alignWithLabel: true,
						lineStyle: {
							color: '#000',
						},
					},
					nameTextStyle: {
						color: '#000',
					},
				},
				yAxis: {
					type: 'value',
					name: '',
					min: 'dataMin',
					max: 'dataMax',
					minInterval: 1, //y轴整数
					axisLine: {
						show: false,
						lineStyle: {
							color: '#585D66',
						},
					},
					splitLine: {
						show: false,
						lineStyle: {
							type: 'dashed',
						},
					},
					axisTick: {
						show: false,
						inside: true,
					},
				},
				color: ['#9146FF', '#FF9797', '#64EB6E', '#FFCA38', '#51A3FF'],
				series: [
					{
						type: 'line',
						name: '左上肢',
						connectNulls: true,
						data: y_data[1],
					},
					{
						type: 'line',
						name: '左下肢',
						connectNulls: true,
						data: y_data[2],
					},
					{
						type: 'line',
						name: '右上肢',
						connectNulls: true,
						data: y_data[3],
					},
					{
						type: 'line',
						name: '右下肢',
						connectNulls: true,
						data: y_data[4],
					},
					{
						type: 'line',
						name: '躯干',
						connectNulls: true,
						data: y_data[5],
					},
				],
			}
			echartsMorning.setOption(option)
			this.$echartsResize(echartsMorning)
		},

		setZtEcharts() {
			this.progressData.forEach((item, index) => {
				var name = 'echartsFour' + index
				let echartStatus = this.$echarts.init(document.getElementById(name))
				let options = {
					tooltip: {
						trigger: 'axis',
					},
					grid: {
						left: '3%',
						right: '5%',
						bottom: '10%',
						top: '20%',
						containLabel: true,
					},
					xAxis: {
						type: 'category',
						boundaryGap: true,
						data:
							item.data &&
							item.data.map(item => {
								return {
									value: item.date,
									textStyle: {
										color: '#585D66',
									},
								}
							}),
						axisLabel: {
							padding: [10, 0, 0, 0],
							interval: 0,
							rotate: 45,
							margin: 15,
						},
						axisLine: {
							lineStyle: {
								color: '#999',
							},
						},
						axisTick: {
							show: false,
							alignWithLabel: true,
							lineStyle: {
								color: '#000',
							},
						},
						nameTextStyle: {
							color: '#000',
						},
					},
					yAxis: {
						type: 'value',
						name: '',
						min: 'dataMin',
						max: 'dataMax',
						minInterval: 1, //y轴整数
						axisLine: {
							show: false,
							lineStyle: {
								color: '#585D66',
							},
						},
						splitLine: {
							show: false,
							lineStyle: {
								type: 'dashed',
							},
						},
						axisTick: {
							show: false,
							inside: true,
						},
					},
					color: ['#0055E9'],
					series: [
						{
							type: 'line',
							data:
								item.data &&
								item.data.map(item => {
									return item.value
								}),
							symbol: 'circle', //标记的图形
							symbolSize: 25, // 拐点的大小
							label: {
								normal: {
									show: true,
									color: '#fff', // 数字颜色
									position: ['50%', '35%'],
									offset: [0, 0],
									align: 'center',
								},
							},

							markLine: {
								data: [
									{
										type: 'average',
										name: 'Avg',
										label: {
											show: true,
											formatter: '平均值:{c}',
											position: 'insideEndTop',
										},
									},
								],
							},

							markArea: {
								silent: true,
								data: [],
							},
						},
					],
				}
				var a_data = []
				this.stageJudgeData[index + 8] &&
					this.stageJudgeData[index + 8].forEach(item => {
						a_data.push([
							{
								name: '',
								yAxis: item.min,
								itemStyle: {
									color: this.colors[item.type - 1],
								},
								label: {
									position: 'insideLeft',
								},
							},
							{
								yAxis: item.max == '' ? 10000 * 10000 : item.max,
							},
						])
					})
				options.series[0].markArea.data = a_data
				echartStatus.setOption(options)
				this.$echartsResize(echartStatus)
			})
		},

		// 弹框数据清空
		setDialogForm() {
			this.value2 = new Date()
			this.$set(this.from, 'morning', '')
			this.$set(this.from, 'weight', '')
			this.$set(this.from, 'sleep', '')

			this.progressClick(0, 1)
			this.progressClick(0, 2)
			this.progressClick(0, 3)
			this.progressClick(0, 4)

			this.leftarm = false
			this.leftarmValue = 0

			this.rightarm = false
			this.rightarmValue = 0

			this.leftleg = false
			this.leftlegValue = 0

			this.rightleg = false
			this.rightlegValue = 0

			this.bosom = false
			this.bosomValue = 0
		},

		create() {
			// for(var i=1; i<5; i++) {
			//   this.progressClick(0, i)
			// }
			this.$set(this.from, 'morning', '')
			this.$set(this.from, 'weight', '')
			this.$set(this.from, 'sleep', '')
			let date = this.getFullDate(this.value2)
			this.$axios
				.post(
					'/p/statusrecord/read',
					this.$qs({
						staff_uuid: this.$route.query.uuid,
						date: date,
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.echartsData = res.data.data.echarts
						var oneType = true,
							twoType = true,
							threeType = true,
							fourType = true
						res.data.data.status.forEach(item => {
							if (item.type == 1) {
								this.$set(this.from, 'morning', item.value)
							} else if (item.type == 2) {
								this.$set(this.from, 'weight', item.value)
							} else if (item.type == 3) {
								this.$set(this.from, 'sleep', item.value)
							} else if (item.type == 8) {
								oneType = false
								this.progressClick(item.value, 1)
							} else if (item.type == 9) {
								twoType = false
								this.progressClick(item.value, 2)
							} else if (item.type == 10) {
								threeType = false
								this.progressClick(item.value, 3)
							} else if (item.type == 11) {
								fourType = false
								this.progressClick(item.value, 4)
							}
						})

						if (oneType) {
							this.progressClick(0, 1)
						}
						if (twoType) {
							this.progressClick(0, 2)
						}
						if (threeType) {
							this.progressClick(0, 3)
						}
						if (fourType) {
							this.progressClick(0, 4)
						}

						this.leftarm = false
						this.leftarmValue = 0

						this.rightarm = false
						this.rightarmValue = 0

						this.leftleg = false
						this.leftlegValue = 0

						this.rightleg = false
						this.rightlegValue = 0

						this.bosom = false
						this.bosomValue = 0
						res.data.data.pain.length &&
							res.data.data.pain.forEach(item => {
								if (item.position == 1) {
									this.leftarm = true
									this.leftarmValue = Number(item.value)
								} else if (item.position == 2) {
									this.leftleg = true
									this.leftlegValue = Number(item.value)
								} else if (item.position == 3) {
									this.rightarm = true
									this.rightarmValue = Number(item.value)
								} else if (item.position == 4) {
									this.rightleg = true
									this.rightlegValue = Number(item.value)
								} else if (item.position == 5) {
									this.bosom = true
									this.bosomValue = Number(item.value)
								}
							})

						// this.setEcharts()
					}
				})
		},
		statusChange(value, index) {
			let type = 0
			switch (index) {
				case 1:
					if (value < 0) type = 1
					else if (value > 150) type = 2
					break
				case 2:
					if (value < 0) type = 1
					else if (value > 200) type = 2
					break
			}
			if (type == 1) {
				return this.$message({
					message: '您输入的结果值可能太小哦！',
					type: 'error',
				})
			} else if (type == 2) {
				return this.$message({
					message: '您输入的结果值可能太大哦！',
					type: 'error',
				})
			}

			// let date = this.getFullDate(this.value1)
			// this.$axios
			// 	.post(
			// 		'/p/statusrecord/add',
			// 		this.$qs({
			// 			staff_uuid: this.$route.query.uuid,
			// 			date: date,
			// 			type: index,
			// 			value: value ? value : 0,
			// 		})
			// 	)
			// 	.then(res => {
			// 		if (res.data.code == 0) {
			// 			this.$message({
			// 				type: 'success',
			// 				message: res.data.message,
			// 			})
			// 			this.create()
			// 		} else {
			// 			this.$message({
			// 				type: 'error',
			// 				message: res.data.message,
			// 			})
			// 		}
			// 	})
		},
		progressClick(index, rows, type, editType) {
			if (this.edit && editType) {
				this.$message({
					message: '暂无权限',
					type: 'warning',
				})
				return false
			}
			if (type) {
				let etype = 0
				switch (rows) {
					case 1:
						etype = 8
						break
					case 2:
						etype = 9
						break
					case 3:
						etype = 10
						break
					case 4:
						etype = 11
						break
				}
				this.statusChange(index, etype)
			}
			if (index > 0 && index <= 3) {
				this.progressOptions[rows] = {
					value: index,
					color: '#81BDFF',
					width: 10 * index + '%',
				}
			} else if (index >= 4 && index <= 7) {
				this.progressOptions[rows] = {
					value: index,
					color: '#EC8B25',
					width: 10 * index + '%',
				}
			} else if (index >= 8 && index <= 10) {
				this.progressOptions[rows] = {
					value: index,
					color: '#FF8181',
					width: 10 * index + '%',
				}
			} else if (index == 0) {
				this.progressOptions[rows] = {
					value: index,
					color: '#999999',
					width: index + '%',
				}
			}
		},
		valueChange(name) {
			let value = this.from[name]
			var regExp = /^[1-9][0-9]*[.]?([0-9]+)?$/
			if (!regExp.test(value)) {
				this.from[name] = ''
				return false
			}
		},
		diaClick(e, index) {
			if (e.target.className !== 'progress_dia') {
				return false
			}
			let clientWidth = e.target.clientWidth
			let layerX = e.layerX
			let a = this.progressOptions[index].width.slice(0, -1)
			let clickX = clientWidth / a
			let num = Math.round(layerX / clickX)
			let etype = 0
			switch (index) {
				case 1:
					etype = 8
					break
				case 2:
					etype = 9
					break
				case 3:
					etype = 10
					break
				case 4:
					etype = 11
					break
			}
			if (num < 5) {
				this.progressOptions[index] = {
					width: 0,
					color: '#999',
					value: 0,
				}
				this.statusChange(0, etype)
			} else if (num >= 5 && num <= 10) {
				this.progressOptions[index] = {
					width: '10%',
					color: '#81BDFF',
					value: 1,
				}
				this.statusChange(1, etype)
			} else if (num >= 11 && num <= 20) {
				this.progressOptions[index] = {
					width: '20%',
					color: '#81BDFF',
					value: 2,
				}
				this.statusChange(2, etype)
			} else if (num >= 21 && num <= 30) {
				this.progressOptions[index] = {
					width: '30%',
					color: '#81BDFF',
					value: 3,
				}
				this.statusChange(3, etype)
			} else if (num >= 31 && num <= 40) {
				this.progressOptions[index] = {
					width: '40%',
					color: '#EC8B25',
					value: 4,
				}
				this.statusChange(4, etype)
			} else if (num >= 41 && num <= 50) {
				this.progressOptions[index] = {
					width: '50%',
					color: '#EC8B25',
					value: 5,
				}
				this.statusChange(5, etype)
			} else if (num >= 51 && num <= 60) {
				this.progressOptions[index] = {
					width: '60%',
					color: '#EC8B25',
					value: 6,
				}
				this.statusChange(6, etype)
			} else if (num >= 61 && num <= 70) {
				this.progressOptions[index] = {
					width: '70%',
					color: '#EC8B25',
					value: 7,
				}
				this.statusChange(7, etype)
			} else if (num >= 71 && num <= 80) {
				this.progressOptions[index] = {
					width: '80%',
					color: '#FF8181',
					value: 8,
				}
				this.statusChange(8, index)
			} else if (num >= 81 && num <= 90) {
				this.progressOptions[index] = {
					width: '90%',
					color: '#FF9181',
					value: 9,
				}
				this.statusChange(9, etype)
			} else if (num >= 91 && num <= 100) {
				this.progressOptions[index] = {
					width: '100%',
					color: '#FF9181',
					value: 10,
				}
				this.statusChange(10, etype)
			}
		},
		setZBackground(value) {
			if (value == '') {
				return ''
			} else if (value >= 0 && value <= 2) {
				return '#FFCBCB'
			} else if (value >= 3 && value <= 4) {
				return '#FFE5E5'
			} else if (value >= 5 && value <= 6) {
				return '#FFFCBB'
			} else if (value >= 7 && value <= 8) {
				return '#DBECFF'
			} else if (value >= 9 && value <= 10) {
				return '#D6FFD9'
			}
		},
		setTBackground(value) {
			if (value == '') {
				return ''
			} else if (value >= 0 && value <= 2) {
				return '#D6FFD9'
			} else if (value >= 3 && value <= 4) {
				return '#DBECFF'
			} else if (value >= 5 && value <= 6) {
				return '#FFFCBB'
			} else if (value >= 7 && value <= 8) {
				return '#FFE5E5'
			} else if (value >= 9 && value <= 10) {
				return '#FFCBCB'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.entry_dialog {
	padding: 0 20px;
	.ctm_content {
		display: flex;
		align-items: center;
		margin-top: 40px;
		.el-input {
			width: 20%;
			margin-right: 6%;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	.bot_left {
		width: 70%;
		h2 {
			text-align: center;
			margin: 30px 0;
			font-size: 20px;
			font-family: PingFangSC-Semibold, PingFang SC;
			font-weight: 600;
		}
		ul {
			width: 92%;
			margin-left: 3%;
			margin-top: 70px;
			li {
				display: flex;
				margin-bottom: 30px;
				.con_name {
					width: 120px;
					display: inline-block;
					font-size: 16px;
					font-weight: 500;
					margin-right: 20px;
					color: #666;
					i {
						color: #666;
						font-size: 18px;
					}
				}
				.con_progress {
					flex: 1;
					height: 100%;
					margin-top: 5px;
					position: relative;
					> span {
						display: inline-block;
						width: 9%;
						background: #f1f1f1;
						border-radius: 10px;
						margin-right: 1%;
						color: #f1f1f1;
						z-index: 9;
						&:hover {
							cursor: pointer;
						}
					}
					.progress_dia {
						position: absolute;
						top: 0;
						left: 0;
						width: 0px;
						height: 100%;
						border-radius: 10px;
						border-top-right-radius: 0;
						border-bottom-right-radius: 0;
						transition: width 1s;
						z-index: 8;
						&:hover {
							cursor: pointer;
						}
						> span {
							position: absolute;
							top: -8px;
							right: -29px;
							display: block;
							width: 25px;
							height: 25px;
							border: 4px solid #999999;
							color: #999999;
							text-align: center;
							line-height: 25px;
							border-radius: 50%;
							z-index: 10;
						}
					}
				}
			}
		}
	}
	.bot_right {
		width: 70%;
		.bot_right_content {
			height: 290px;
			position: relative;
			.body_con {
				width: 93px;
				height: 100%;
				margin: 0 auto;
				position: relative;
				img {
					height: 100%;
				}
				.leftarm {
					position: absolute;
					width: 22.5px;
					height: 120px;
					right: 1.5px;
					top: 48.5px;
					z-index: 2;
					img {
						width: 100%;
						height: 100%;
					}
					&:hover {
						cursor: pointer;
					}
				}

				.rightarm {
					position: absolute;
					width: 23px;
					height: 120px;
					left: 0px;
					top: 48.5px;
					z-index: 2;
					img {
						width: 100%;
						height: 100%;
					}
					&:hover {
						cursor: pointer;
					}
				}

				.leftleg {
					position: absolute;
					width: 30px;
					height: 145.5px;
					right: 17.5px;
					bottom: 0px;
					img {
						width: 100%;
						height: 100%;
					}
					&:hover {
						cursor: pointer;
					}
				}

				.rightleg {
					position: absolute;
					width: 29.5px;
					height: 145.5px;
					left: 16.5px;
					bottom: 0px;
					img {
						width: 100%;
						height: 100%;
					}
					&:hover {
						cursor: pointer;
					}
				}

				.bosom {
					position: absolute;
					width: 66px;
					height: 130px;
					left: 13px;
					top: 36px;
					z-index: 1;
					img {
						width: 100%;
						height: 100%;
					}
					&:hover {
						cursor: pointer;
					}
				}
			}
			.leftarmSelect {
				position: absolute;
				right: 20px;
				top: 65px;
				span {
					margin-right: 15px;
					font-weight: 500;
					color: #1f2633;
					font-size: 17px;
				}
				img {
					width: 130px;
					position: absolute;
					left: -140px;
					top: 5px;
				}
			}
			.rightarmSelect {
				position: absolute;
				left: 20px;
				top: 115px;
				span {
					margin-left: 15px;
					font-weight: 500;
					color: #1f2633;
					font-size: 17px;
				}
				img {
					transform: rotateY(180deg);
					width: 130px;
					position: absolute;
					right: -135px;
					top: 5px;
				}
			}
			.leftlegSelect {
				position: absolute;
				right: 20px;
				bottom: 50px;
				span {
					margin-right: 15px;
					font-weight: 500;
					color: #1f2633;
					font-size: 17px;
				}
				img {
					width: 150px;
					position: absolute;
					left: -155px;
					top: 5px;
				}
			}
			.rightlegSelect {
				position: absolute;
				left: 20px;
				bottom: 30px;
				span {
					margin-left: 15px;
					font-weight: 500;
					color: #1f2633;
					font-size: 17px;
				}
				img {
					transform: rotateY(180deg);
					width: 145px;
					position: absolute;
					right: -151px;
					top: 5px;
				}
			}
			.bosomSelect {
				position: absolute;
				left: 20px;
				top: 40px;
				span {
					margin-left: 15px;
					font-weight: 500;
					color: #1f2633;
					font-size: 17px;
				}
				img {
					transform: rotateY(180deg);
					width: 160px;
					position: absolute;
					right: -170px;
					top: 3px;
				}
			}
			.el-select {
				width: 120px;
			}
		}
	}
}
.top_title_qs {
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	.top_title {
		display: flex;
		align-items: center;
		font-size: 18px;
		color: #333;
		font-weight: 500;
		font-family: PingFangSC-Medium, PingFang SC;
		span {
			display: inline-block;
			width: 6px;
			height: 20px;
			background: #0055e9;
			border-radius: 3px;
			margin-right: 10px;
		}
	}
	.color_area {
		span {
			display: inline-block;
			width: 12px;
			height: 12px;
			margin-right: 10px;
			margin-left: 20px;
			color: #666;
			&:first-child {
				background: #d6ffd9;
			}
			&:nth-child(2) {
				background: #dbecff;
			}
			&:nth-child(3) {
				background: #fffcbb;
			}
			&:nth-child(4) {
				background: #ffe5e5;
			}
		}
	}
}
.subjective_view {
	.interMant_top {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 20px;
		background: #fff;
		margin-bottom: 10px;
		box-sizing: border-box;
		border-radius: 5px;
		.interTop_left {
			width: 56%;
			display: flex;
			align-items: center;
			font-size: 16px;
			color: #333;
			.el-avatar {
				width: 60px;
				height: 60px;
				img {
					width: 100%;
				}
			}
			span {
				margin-left: 5px;
				margin-right: 20px;
				font-size: 16px;
			}
		}
		.interTop_right {
			.item {
				font-size: 16px;
				color: #666;
				margin-right: 10px;
			}
			> .el-date-editor {
				margin-right: 20px;
				border-radius: 0;
				border: none;
				margin-right: 20px;
				/deep/ .el-input__inner {
					border: none;
					border-radius: 0;
					border-bottom: 2px solid #ccc;
					&:focus {
						border-color: #0055e9;
					}
				}
				&:focus {
					border-color: #0055e9;
				}
			}
			> span {
				font-size: 16px;
				margin-right: 20px;
				&:hover {
					cursor: pointer;
				}
			}
		}
	}
}
.subjective_content {
	background: #fff;
	margin-top: 15px;
	border-radius: 5px;
	padding: 20px;

	.echarts {
		width: 100%;
		height: 400px;
		border: 1px solid #e3e3e3;
		border-radius: 10px;
		margin-bottom: 20px;
	}
}
.status_content {
	background: #fff;
	margin-top: 15px;
	border-radius: 5px;
	padding: 20px;

	.echarts_view {
		border: 1px solid #e3e3e3;
		border-radius: 10px;
		margin-bottom: 20px;

		p {
			text-align: center;
			margin-top: 20px;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1f2633;
			i {
				color: #666;
			}
		}
	}
	.echarts {
		width: 100%;
		height: 400px;
	}
}

.table_content {
	border: 1px solid #e3e3e3;
	border-radius: 10px;
	margin-bottom: 20px;
	box-sizing: border-box;
	padding: 0 20px 30px;
	.table_head {
		line-height: 70px;
		text-align: center;
		position: relative;
		p {
			display: inline-block;
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #1f2633;
		}
		div {
			float: right;
			position: absolute;
			top: 0px;
			right: 10px;
			color: #666;
			font-size: 14px;
			span {
				width: 10px;
				height: 10px;
				display: inline-block;
				margin-left: 15px;
				margin-right: 5px;
				&:first-child {
					background: #d6ffd9;
				}
				&:nth-child(2) {
					background: #dbecff;
				}
				&:nth-child(3) {
					background: #fffcbb;
				}
				&:nth-child(4) {
					background: #ffe5e5;
				}
				&:nth-child(5) {
					background: #ffcbcb;
				}
			}
		}
	}
	.el-table {
		border: 1px solid #e3e3e3;
		/deep/ thead tr th {
			background: #f4f6f9;
		}
		/deep/ tbody > tr > td {
			padding: 0 !important;
		}
		/deep/ .cell {
			padding: 0 !important;
		}
		.value_content {
			height: 50px;
			line-height: 50px;
		}
	}
}
</style>

<style lang="scss">
.custom_date_class {
	span {
		background: #ea6151;
		color: red;
		border-radius: 50%;
		color: #fff !important;
		&:hover {
			background-color: #409eff;
		}
	}
	&::after {
		content: '';
		display: inline-block;
		position: absolute;
		width: 100%;
		font-size: 12px;
		color: red;
		bottom: -15px;
		left: 0;
		text-align: center;
		white-space: nowrap;
	}
}
.otim {
	width: 90%;
	overflow: hidden; //超出的文本隐藏
	text-overflow: ellipsis; //溢出用省略号显示
	white-space: nowrap; //溢出不换行
}
.ye {
	background-color: #fff;
}
</style>
